<template>
	<div>
		<div class="dialog-box" v-if="visible">
			<div class="dialog-sign-body">
				<div class="tips" v-if="hasTips">
					<div v-html="tipText"></div>
				</div>
				<div @touchmove.stop.prevent="">
					<canvas class="myCanvas" ref="canvasF" @touchstart='touchstart' @touchmove='touchmove'
						@touchend='touchend'></canvas>
				</div>
			</div>
			<div class="dialog-footer">
				<div class="foot-sign-btn" @click.stop.prevent="handleCancel">取消</div>
				<div class="foot-sign-btn" @click.stop.prevent="handleReset">重签</div>
				<div class="foot-sign-btn confirm" @click.stop.prevent="handleConfirm">确认签字并盖章</div>
			</div>

		</div>
		<div class="mark" @touchstart.stop.prevent="" v-show="visible"></div>
	</div>
</template>

<script>
export default {
	props: {
		hasPass: {
			type: Boolean,
			default: false,
		},
		hasTips: {
			type: Boolean,
			default: false,
		},
		tipText: {
			type: String,
			default: "*签字后协议不可再修改<br/>如需修改，请重新生成合同重新签字"
		}
	},
	data() {
		return {
			visible: false,
			canvasEl: null,
			canvasTxt: null, //绘图图像
			points: [], //路径点集合,
			canvasWidth: 0,
			canvasHeight: 0,
			passIndex: 1,
			tempFilePath: "",
			stageInfo: '',
			imgUrl: '',
			client: {},
			startX: 0,
			startY: 0,
			moveY: 0,
			moveX: 0,
			endY: 0,
			endX: 0,
			w: null,
			h: null,
			isDown: false
		}
	},
	mounted() {

	},
	methods: {
		show() {
			this.visible = true
			this.initCanvas()
		},
		hide() {
			this.visible = false
		},
		initCanvas() {
			this.$nextTick(() => {
				let canvas = this.$refs.canvasF
				if (canvas) {
					this.canvasEl = this.$refs.canvasF
					canvas.height = 210
					canvas.width = 300
					// 创建 context 对象
					this.canvasTxt = canvas.getContext('2d')
					this.stageInfo = canvas.getBoundingClientRect()
				}
			})
		},
		touchstart(ev) {
			ev = ev || event
			ev.preventDefault()
			if (ev.touches.length == 1) {
				let obj = {
					x: ev.targetTouches[0].clienX,
					y: ev.targetTouches[0].clientY,
				}
				this.startX = obj.x
				this.startY = obj.y
				this.canvasTxt.beginPath()
				this.canvasTxt.moveTo(this.startX, this.startY)
				this.canvasTxt.lineTo(obj.x, obj.y)
				this.canvasTxt.stroke()
				this.canvasTxt.closePath()
				this.points.push(obj)
			}
		},
		//触摸移动，获取到路径点
		touchmove(ev) {
			ev = ev || event
			ev.preventDefault()
			if (ev.touches.length == 1) {
				let obj = {
					x: ev.targetTouches[0].clientX - this.stageInfo.left,
					y: ev.targetTouches[0].clientY - this.stageInfo.top
				}
				this.moveY = obj.y
				this.moveX = obj.x
				this.canvasTxt.beginPath()
				this.canvasTxt.lineWidth = 5
				this.canvasTxt.moveTo(this.startX, this.startY)
				this.canvasTxt.lineTo(obj.x, obj.y)
				this.canvasTxt.stroke()
				this.canvasTxt.closePath()
				this.startY = obj.y
				this.startX = obj.x
				this.points.push(obj)
			}
		},
		// 触摸结束，将未绘制的点清空防止对后续路径产生干扰
		touchend(ev) {
			ev = ev || event
			ev.preventDefault()
			if (ev.touches.length == 1) {
				let obj = {
					x: ev.targetTouches[0].clientX - this.stageInfo.left,
					y: ev.targetTouches[0].clientY - this.stageInfo.top
				}
				this.canvasTxt.beginPath()
				this.canvasTxt.moveTo(this.startX, this.startY)
				this.canvasTxt.lineTo(obj.x, obj.y)
				this.canvasTxt.stroke()
				this.canvasTxt.closePath()
				this.points.push(obj)
			}
		},
		//签字
		handleConfirm() {
			this.imgUrl = this.$refs.canvasF.toDataURL();
			var blank = document.createElement('canvas');//系统获取一个空canvas对象
			blank.width = this.canvasEl.width;
			blank.height = this.canvasEl.height;
			if (blank.toDataURL() == this.imgUrl) {
				alert("请先签名");
			} else {
				this.$emit("signAutoGraph", this.imgUrl)
			}

		},
		handleReset() {
			this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
			this.points = []
			this.imgUrl = ""
		},
		handleCancel() {
			this.handleReset()
			this.visible = false
		}
	}
}
</script>

<style scoped>
.mark {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 20;
}

.dialog-box {
	width: 80vw;
	height: 310px;
	border-radius: 0;
	box-sizing: border-box;
	background-color: #fff;
	padding: 0;
	font-size: 0.8rem;
	position: fixed;
	left: 50%;
	top: 50%;
	margin-top: -155px;
	margin-left: -40vw;
	z-index: 1000;
}

.dialog-sign-body {
	padding: 0;
	text-align: center;
}

.agree-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3.2rem;
	padding: 0 1.06rem;
}

.agree-item {
	width: 4.8rem;
	height: 2.13rem;
	line-height: 2.13rem;
	color: #5C5C5C;
	font-size: 0.64rem;
	background: #FFFFFF;
	border: 1px solid #676767;
}

.agree-item.active {
	background-color: #F83144;
	border: 1px solid #F83144;
	color: #fff;
}

.dialog-body {
	padding: 1.6rem 1.06rem 2.4rem 1.06rem;
	text-align: center;
}

.dialog-footer {
	display: flex;
	border-top: 1px solid #D0D0D0;
}

.foot-sign-btn {
	flex: 1;
	height: 2.66rem;
	line-height: 2.66rem;
	text-align: center;
	font-size: 0.74rem;
	color: #000;
}

.foot-sign-btn.confirm {
	flex: 0 0 50%;
}

.foot-btn {
	flex: 1;
	height: 2.13rem;
	line-height: 2.13rem;
	text-align: center;
	font-size: 2.13rem;
	border-right: 1px solid #D0D0D0;
	color: #000;
}

.foot-btn:last-child {
	border: 0;
}

.mycanvas {
	margin: auto 0rpx;
	width: 100%;
	height: 13.3rem;
	background-color: #ececec;
}

.tips {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3.2rem;
	color: #ff0000;
}

.myCanvas {
	height: 210px;
	width: 100%;
}
</style>
